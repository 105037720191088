import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 720.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M2295 6460 c-38 -4 223 -7 580 -7 358 0 607 4 555 7 -116 9 -1038 9
-1135 0z"/>
<path d="M1032 6188 c-6 -14 -19 -28 -29 -31 -16 -4 -15 -6 5 -12 12 -4 22
-15 22 -26 0 -11 5 -19 10 -19 6 0 10 7 10 15 0 9 12 20 28 26 l27 11 -27 10
c-16 5 -28 18 -31 31 -3 21 -4 21 -15 -5z"/>
<path d="M6102 6188 c-6 -16 -18 -28 -27 -28 -8 0 -15 -4 -15 -10 0 -5 9 -10
20 -10 13 0 20 -7 20 -20 0 -11 5 -20 10 -20 6 0 10 9 10 20 0 13 7 20 19 20
30 0 34 17 7 24 -15 4 -26 15 -29 29 -3 21 -4 21 -15 -5z"/>
<path d="M2995 6178 c-6 -17 -138 -421 -170 -523 -14 -44 -32 -96 -40 -115 -7
-19 -28 -82 -45 -140 -18 -58 -47 -148 -65 -200 -18 -52 -64 -194 -104 -315
-39 -121 -134 -411 -211 -645 -77 -234 -136 -428 -132 -432 8 -8 72 -17 267
-37 129 -14 408 -28 711 -37 l241 -6 73 218 c40 121 75 225 79 232 4 7 40 -87
80 -213 68 -210 74 -225 97 -225 23 0 23 3 22 66 -2 42 -5 59 -10 47 -5 -11
-7 3 -5 34 2 28 0 58 -4 65 -6 9 -9 8 -10 -7 0 -14 -6 -9 -19 19 -9 21 -15 48
-12 59 5 20 6 20 14 1 5 -11 6 4 4 41 -3 33 -4 114 -4 180 0 66 0 139 -1 162
-1 34 2 41 14 37 19 -8 19 6 0 42 -8 16 -15 40 -15 54 0 20 -2 22 -11 10 -8
-12 -9 -9 -5 12 11 48 25 78 38 78 10 0 10 2 1 8 -8 5 -2 35 22 107 18 55 54
165 80 245 44 139 57 174 76 212 5 10 9 25 9 34 0 10 5 14 15 10 8 -3 15 -1
15 5 0 6 5 7 10 4 13 -8 13 5 0 25 -6 10 -10 11 -10 3 0 -7 -5 -13 -11 -13 -7
0 -8 7 -4 18 4 9 12 35 17 57 5 21 13 44 17 50 4 5 13 28 19 50 7 22 37 116
67 209 30 93 55 174 55 179 0 5 6 23 14 40 19 41 86 269 86 291 0 15 -51 16
-625 16 -492 0 -627 -3 -630 -12z m725 -1685 c-11 -17 -11 -17 -6 0 3 10 6 24
7 30 0 9 2 9 5 0 3 -7 0 -20 -6 -30z m21 -24 c-5 -13 -10 -19 -10 -12 -1 15
10 45 15 40 3 -2 0 -15 -5 -28z m-37 -43 c-3 -15 -8 -25 -11 -23 -2 3 -1 17 3
31 3 15 8 25 11 23 2 -3 1 -17 -3 -31z m36 -11 c0 -8 -4 -15 -10 -15 -5 0 -7
7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-80 -102 c-11 -17 -11 -17 -6 0 3 10
6 24 7 30 0 9 2 9 5 0 3 -7 0 -20 -6 -30z"/>
<path d="M1131 6152 c-2 -65 -78 -103 -144 -71 -21 10 -21 9 2 -10 33 -29 93
-28 122 2 30 31 40 63 29 89 -8 21 -9 20 -9 -10z"/>
<path d="M6200 6135 c0 -9 -11 -27 -25 -40 -27 -28 -80 -33 -118 -14 -22 12
-22 12 -3 -10 29 -32 99 -29 131 5 14 15 25 30 25 35 0 4 3 14 6 23 3 9 1 16
-5 16 -6 0 -11 -7 -11 -15z"/>
<path d="M4031 5404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3984 5218 c3 -13 3 -17 0 -10 -3 6 -10 12 -15 12 -6 0 -8 -4 -5 -9
4 -5 0 -11 -6 -14 -9 -3 -7 -7 6 -12 11 -5 21 -3 27 6 12 19 11 49 -2 49 -6 0
-8 -10 -5 -22z"/>
<path d="M3977 5148 c-4 -13 -1 -29 7 -36 12 -12 14 -9 12 16 -2 39 -12 49
-19 20z"/>
<path d="M3990 5051 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M3818 4775 c-6 -30 -3 -43 8 -33 3 4 4 19 2 35 l-3 28 -7 -30z"/>
<path d="M3824 4716 c-3 -8 -1 -22 4 -32 7 -13 9 -10 8 15 -1 34 -4 38 -12 17z"/>
<path d="M3792 4695 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3757 4593 c-3 -15 -1 -30 4 -31 5 -2 7 -8 3 -13 -3 -5 0 -9 6 -9 7
0 9 13 4 38 -9 47 -10 49 -17 15z"/>
<path d="M3842 4375 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3765 4340 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3851 4304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5100 4224 c292 -89 480 -186 480 -249 0 -24 -73 -75 -150 -104 -557
-212 -2293 -278 -3288 -125 -194 30 -297 54 -399 95 -113 45 -150 79 -132 125
18 49 141 124 301 185 49 18 86 34 84 37 -7 6 -163 -28 -234 -52 -361 -123
-310 -324 118 -466 255 -85 596 -143 1065 -182 208 -17 995 -17 1195 1 545 47
882 105 1174 202 207 69 328 150 357 236 43 131 -102 234 -411 292 -58 11
-136 22 -175 25 l-70 6 85 -26z"/>
<path d="M3842 4220 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3795 3990 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3811 3934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2040 3273 c-46 -142 -121 -372 -167 -512 -46 -140 -83 -258 -83
-263 0 -4 282 -7 627 -6 l626 3 74 225 c41 124 107 326 148 449 41 124 72 227
70 231 -2 3 -57 7 -122 8 -262 5 -694 49 -949 97 -72 14 -133 25 -135 25 -3 0
-43 -116 -89 -257z"/>
<path d="M3870 3393 c0 -5 20 -66 44 -138 66 -191 67 -196 95 -267 4 -13 16
-48 26 -78 27 -82 32 -88 40 -50 4 18 3 42 -4 54 -14 28 -48 135 -71 221 -10
39 -33 114 -51 168 -29 85 -36 97 -56 97 -13 0 -23 -3 -23 -7z m197 -505 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4079 2822 c-3 -20 -2 -29 4 -23 5 5 7 19 5 32 -3 20 -5 19 -9 -9z"/>
<path d="M2604 2171 c-2 -2 -4 -33 -4 -68 l0 -63 50 0 50 0 0 -149 c0 -164 1
-161 -66 -161 l-34 0 0 -65 0 -65 165 0 165 0 0 65 c0 45 -4 65 -12 66 -7 0
-29 1 -48 2 l-35 2 -3 152 -3 152 48 3 48 3 0 65 0 65 -158 0 c-87 0 -160 -2
-163 -4z"/>
<path d="M3051 2168 c-45 -16 -50 -35 -51 -170 0 -120 1 -128 24 -152 23 -26
23 -26 200 -26 l176 0 0 -39 c0 -51 -7 -52 -223 -50 l-172 1 -3 -66 -3 -66
245 0 245 0 20 26 c19 24 21 40 21 150 0 113 -2 125 -22 148 -23 25 -25 26
-180 27 -199 1 -198 1 -198 50 l0 39 200 0 200 0 0 71 0 70 -227 -2 c-126 -1
-239 -6 -252 -11z"/>
<path d="M3668 2120 c-1 -33 -1 -65 0 -70 2 -6 81 -10 203 -10 l199 0 0 -150
0 -150 -135 0 -134 0 -3 98 -3 97 -62 3 -63 3 0 -171 0 -170 239 0 c220 0 241
1 262 19 24 19 24 20 26 263 3 202 0 246 -12 262 -21 27 -85 34 -317 35 l-196
1 -4 -60z"/>
<path d="M4485 2156 c-7 -12 -32 -66 -55 -121 -57 -138 -139 -331 -161 -384
-11 -24 -19 -45 -19 -47 0 -2 32 -4 70 -4 l70 0 16 40 16 40 133 0 132 0 18
-40 18 -40 69 0 c37 0 68 4 68 10 0 5 -15 44 -34 87 -19 43 -71 166 -116 273
-46 107 -85 197 -87 200 -2 2 -31 5 -64 5 -51 2 -63 -1 -74 -19z m112 -253
c18 -42 33 -80 33 -85 0 -4 -34 -8 -75 -8 -59 0 -75 3 -75 14 0 8 4 17 8 20 5
3 21 37 37 76 15 38 30 68 33 65 3 -3 20 -39 39 -82z"/>
<path d="M4790 2176 c-3 -2 -3 -34 -2 -70 l4 -66 84 0 84 0 0 -220 0 -220 65
0 65 0 0 220 0 219 88 3 87 3 3 59 c2 37 -1 62 -8 66 -13 8 -463 14 -470 6z"/>
<path d="M5395 2068 c-26 -62 -81 -191 -121 -285 -41 -95 -74 -175 -74 -178 0
-3 32 -5 70 -5 l70 0 16 40 16 40 133 0 132 0 18 -40 18 -40 70 0 c61 0 69 2
65 18 -5 16 -228 543 -234 551 -2 1 -32 5 -67 8 l-64 5 -48 -114z m153 -166
c17 -42 32 -80 32 -85 0 -4 -34 -7 -75 -7 -41 0 -75 2 -75 4 0 2 12 30 26 62
14 33 30 69 35 82 5 12 12 22 16 22 5 0 23 -35 41 -78z"/>
<path d="M1663 2133 c-18 -44 -203 -482 -220 -520 -4 -10 13 -13 65 -13 l71 0
17 40 18 40 134 0 134 0 15 -40 16 -40 69 0 c37 0 68 4 68 9 0 5 -13 38 -29
73 -34 74 -141 328 -141 334 0 2 -16 38 -35 79 l-34 75 -66 0 -66 0 -16 -37z
m125 -231 c18 -40 32 -77 32 -82 0 -12 -136 -14 -144 -2 -6 11 66 174 74 165
4 -5 21 -41 38 -81z"/>
<path d="M1941 2142 c26 -31 30 -36 143 -189 l76 -103 0 -125 0 -125 65 0 65
0 0 124 0 124 46 63 c26 34 51 68 56 73 14 18 98 129 120 159 l19 27 -84 0
-85 0 -64 -85 c-34 -46 -67 -83 -72 -82 -5 2 -37 39 -70 82 l-62 80 -88 3 -88
3 23 -29z"/>
<path d="M1650 1306 c0 -2 18 -36 40 -76 33 -59 40 -83 40 -126 l0 -54 35 0
35 0 0 54 c0 43 7 67 40 126 22 40 40 74 40 76 0 2 -15 4 -34 4 -30 0 -38 -6
-60 -41 l-24 -42 -21 42 c-19 36 -25 41 -56 41 -19 0 -35 -2 -35 -4z"/>
<path d="M1934 1291 c-48 -29 -64 -59 -64 -117 0 -49 3 -55 42 -88 35 -30 49
-36 87 -36 48 0 91 21 116 58 22 30 21 103 0 139 -35 59 -122 80 -181 44z m96
-56 c52 -27 48 -95 -6 -115 -76 -29 -128 75 -56 114 34 19 28 19 62 1z"/>
<path d="M2159 1258 c-4 -146 5 -179 59 -199 45 -18 113 -5 139 26 21 23 23
36 23 126 l0 99 -30 0 -30 0 0 -85 c0 -79 -2 -86 -25 -101 -23 -15 -27 -15
-50 0 -23 15 -25 22 -25 101 l0 85 -30 0 c-29 0 -30 -1 -31 -52z"/>
<path d="M2410 1180 l0 -130 35 0 c35 0 35 0 35 46 l0 47 35 -47 c32 -41 40
-46 76 -46 l39 0 -27 41 c-26 40 -27 42 -10 71 25 44 22 94 -8 123 -21 22 -33
25 -100 25 l-75 0 0 -130z m118 61 c23 -14 10 -46 -21 -49 -24 -3 -27 0 -27
27 0 32 19 41 48 22z"/>
<path d="M2730 1180 l0 -130 75 0 c67 0 79 3 100 25 30 29 33 83 7 110 -11 12
-16 30 -14 47 3 19 -3 34 -21 52 -22 22 -35 26 -87 26 l-60 0 0 -130z m92 45
c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z
m33 -90 c0 -14 -8 -21 -27 -23 -24 -3 -28 0 -28 23 0 23 4 26 28 23 19 -2 27
-9 27 -23z"/>
<path d="M2956 1302 c-7 -12 -7 -219 0 -237 5 -12 25 -15 90 -15 l84 0 0 29
c0 29 -2 30 -52 33 -41 2 -54 7 -56 21 -3 14 4 17 42 17 44 0 46 1 46 30 0 29
-2 30 -45 30 -38 0 -45 3 -45 20 0 18 7 20 55 20 54 0 55 0 55 30 l0 30 -84 0
c-47 0 -87 -4 -90 -8z"/>
<path d="M3176 1289 c-18 -14 -26 -30 -26 -52 0 -44 12 -58 61 -78 24 -9 45
-21 46 -26 6 -17 -26 -23 -41 -8 -16 16 -76 10 -76 -7 0 -5 13 -23 29 -39 39
-39 100 -41 136 -4 45 44 28 96 -41 125 -24 11 -44 24 -44 30 0 13 23 13 37
-1 15 -15 63 0 63 20 0 54 -94 80 -144 40z"/>
<path d="M3334 1295 c-10 -25 3 -43 33 -47 l28 -3 3 -97 3 -98 34 0 34 0 3 97
3 98 30 5 c23 4 29 10 29 30 0 25 0 25 -97 28 -80 2 -98 0 -103 -13z"/>
<path d="M3930 1188 c-33 -67 -60 -125 -60 -130 0 -4 15 -8 34 -8 26 0 36 5
41 20 5 16 15 20 50 20 35 0 45 -4 50 -20 5 -15 15 -20 41 -20 31 0 35 2 29
21 -11 32 -114 239 -120 239 -3 0 -32 -55 -65 -122z"/>
<path d="M4090 1280 c0 -28 3 -30 35 -30 l35 0 0 -100 0 -100 35 0 35 0 0 100
0 100 36 0 c33 0 35 2 32 28 l-3 27 -102 3 -103 3 0 -31z"/>
<path d="M4336 1202 c-75 -152 -75 -152 -32 -152 26 0 36 5 41 20 5 16 15 20
50 20 35 0 45 -4 50 -20 5 -15 15 -20 41 -20 32 0 35 2 28 23 -9 31 -113 237
-119 237 -3 0 -29 -49 -59 -108z"/>
<path d="M4635 1298 c-3 -8 -4 -66 -3 -129 l3 -114 33 -3 c31 -3 32 -2 32 37
0 41 0 41 39 41 58 0 91 30 91 84 0 69 -25 90 -116 94 -57 3 -75 1 -79 -10z
m116 -58 c27 -15 8 -50 -26 -50 -21 0 -25 5 -25 30 0 32 16 38 51 20z"/>
<path d="M4850 1180 l0 -130 90 0 90 0 0 29 c0 29 -2 30 -52 33 l-53 3 -3 98
-3 97 -34 0 -35 0 0 -130z"/>
<path d="M5033 1294 c-3 -8 -3 -54 0 -102 5 -82 7 -88 37 -115 30 -27 38 -29
88 -25 45 4 60 10 79 32 21 24 23 37 23 127 l0 99 -30 0 -30 0 0 -85 c0 -79
-2 -86 -25 -101 -23 -15 -27 -15 -50 0 -23 15 -25 22 -25 101 l0 85 -30 0
c-19 0 -33 -6 -37 -16z"/>
<path d="M5354 1293 c-43 -21 -64 -61 -64 -121 0 -43 5 -55 30 -81 80 -80 240
-32 240 71 l0 38 -76 0 -75 0 3 -27 c2 -22 9 -29 31 -31 33 -4 35 -18 3 -26
-43 -11 -96 25 -96 65 0 44 73 74 119 49 23 -12 57 21 47 45 -13 35 -107 46
-162 18z"/>
<path d="M3640 1175 l0 -125 65 0 c106 0 164 41 165 117 0 94 -47 133 -161
133 l-69 0 0 -125z m138 49 c42 -29 23 -91 -35 -108 -20 -6 -21 -3 -25 54 -1
34 -1 64 1 66 9 9 38 3 59 -12z"/>
<path d="M1032 1118 c-6 -16 -18 -28 -27 -28 -8 0 -15 -4 -15 -10 0 -5 9 -10
20 -10 13 0 20 -7 20 -20 0 -11 5 -20 10 -20 6 0 10 7 10 15 0 9 12 20 28 26
l27 11 -27 10 c-16 5 -28 18 -31 31 -3 21 -4 20 -15 -5z"/>
<path d="M6102 1118 c-6 -16 -18 -28 -27 -28 -8 0 -15 -4 -15 -10 0 -5 9 -10
20 -10 13 0 20 -7 20 -20 0 -11 5 -20 10 -20 6 0 10 7 10 16 0 9 9 18 20 21
18 5 30 35 13 32 -22 -4 -32 3 -36 24 -3 21 -4 21 -15 -5z"/>
<path d="M1131 1087 c-2 -70 -75 -110 -144 -76 -21 10 -21 9 2 -10 66 -58 184
21 152 101 -9 20 -10 19 -10 -15z"/>
<path d="M6201 1087 c-2 -74 -85 -113 -151 -71 -22 14 -22 12 3 -10 31 -29 76
-34 117 -13 32 16 57 79 42 110 -10 20 -11 18 -11 -16z"/>
<path d="M3160 760 c-113 -3 130 -7 539 -8 409 -1 742 0 739 3 -7 8 -1036 11
-1278 5z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
